import * as React from "react";
import { useState } from 'react';
import continentsImage from './assets/continents.png';
import birdImage from './assets/bird.png';  // Import the new image
import shadowImage from './assets/shadow.png';  // Import the shadow image

function FeatureCard({ title, description }) {
  return (
    <section className="flex flex-col grow p-8 w-full font-medium bg-white rounded-xl border border-solid shadow-lg border-neutral-100 max-md:px-5 max-md:mt-8">
      <h2 className="text-2xl leading-9 text-black">{title}</h2>
      <p className="mt-12 text-xl leading-8 text-zinc-500 max-md:mt-10">{description}</p>
    </section>
  );
}

function MyComponent() {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const continentsImageSize = '1000px 600px';  // Change this value as needed (e.g., '50% 50%', '800px 600px')
  const continentsVerticalOffset = '-140px';  // Positive values move it up, negative down
  const birdImageSize = '300px 300px';  // Size
  const birdHorizontalOffset = '650px';
  const birdVerticalOffset = '185px';  // Positive values move it up, negative down
  const shadowOffset = '-10px';  // Small offset to the right for the shadow

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://flysterna.com/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('Thank you for subscribing!');
        setEmail('');
      } else {
        setMessage('Failed to subscribe. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };
  
  return (
    <div className="flex flex-col pb-10 bg-indigo-50 overflow-hidden" style={{ minHeight: '100vh' }}> 
      <header className="flex gap-5 justify-between items-start px-12 py-7 w-full font-medium text-black bg-white bg-opacity-50 leading-[150%] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="flex gap-3 text-4xl whitespace-nowrap">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6ad8e0f2f683b9e4551803f60ee6c8ef66aeff140590f431cb34d89709ab8ad4?apiKey=84610d0e1b774fbd87650936001dd652&"
            alt="Sterna Logo"
            className="shrink-0 aspect-[1.05] w-[60px]"
          />
          <div className="flex-auto my-auto">Sterna</div>
        </div>
        <nav className="flex gap-5 items-center text-xl max-md:flex-wrap">
        <a href="#tally-open=wvBWAd&tally-width=1600" className="justify-center self-stretch px-6 py-3.5 font-bold text-white whitespace-nowrap bg-red-500 rounded-lg shadow-sm max-md:px-5">Shape Your Travel Assistant</a>
        </nav>
        </header>
        <main className="relative flex flex-col items-start mt-44 ml-40 max-w-full w-full max-md:mt-10 max-md:ml-0 max-md:mr-0 max-md:pb-0" style={{ height: '500px', width: '100vw' }}>
  {/* Shadow image, aligned with continents image */}
  <div className="absolute inset-0 z-1 bg-no-repeat bg-cover" style={{ backgroundImage: `url(${shadowImage})`, backgroundPosition: `right calc(100% - ${continentsVerticalOffset})`, backgroundSize: continentsImageSize, opacity: 0.5 }}>
  </div>
  {/* Background image (continents) with controlled size and vertical offset */}
  <div className="absolute inset-0 z-0 bg-no-repeat bg-cover" style={{ backgroundImage: `url(${continentsImage})`, backgroundPosition: `right calc(100% - ${continentsVerticalOffset})`, backgroundSize: continentsImageSize, opacity: 1 }}>
  </div>
  {/* New image (bird) */}
  <div className="absolute inset-0 z-5 bg-no-repeat bg-cover" style={{ backgroundImage: `url(${birdImage})`, backgroundPosition: `calc(100% - ${birdHorizontalOffset}) calc(100% - ${birdVerticalOffset})`, backgroundSize: birdImageSize, opacity: 1 }}>
  </div>
  {/* Text and form elements */}
  <h1 className="relative z-10 ml-2.5 text-7xl font-bold tracking-tighter text-slate-500 max-md:max-w-full max-md:text-4xl">
  <span>Where are you </span>
  <span className="text-slate-500">flying</span>
  <span>?</span>
  </h1>
  <h2 className="relative z-10 mt-6 ml-2.5 text-4xl text-red-500 leading-[54px] max-md:max-w-full">
  Let your personal assistant help with <span className="font-bold text-red-500">your plan</span>
  </h2>
  <form className="relative z-10 flex gap-4 px-4 py-2 mt-28 border-2 border-solid border-slate-400 rounded-[600px] max-md:flex-wrap max-md:mt-10" onSubmit={handleSubmit}>
  <label htmlFor="email" className="sr-only">Join our mailing list</label>
  <input
    id="email"
    type="email"
    className="flex-1 my-auto text-2xl leading-6 text-ellipsis text-zinc-500 bg-indigo-50 max-md:text-base"
    placeholder="Join our mailing list >>>"
    aria-label="Join our mailing list"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    required
  />
  <button className="justify-center px-6 py-3.5 text-xl font-medium leading-8 text-white whitespace-nowrap bg-red-500 shadow-sm rounded-[600px] max-md:text-base max-md:px-5" type="submit">GO</button>
</form>
<p className="relative z-10 mt-2 text-xl text-zinc-500">Get access to Sterna's free beta!</p>
  {message && <p className="relative z-10 mt-4 text-xl text-red-500">{message}</p>}
</main>
      <section className="flex flex-col items-start px-20 pt-20 pb-9 mt-0 w-full bg-white max-md:px-5 max-md:mt-0 max-md:max-w-full">
        <h2 className="text-4xl font-semibold leading-10 text-slate-500 max-md:max-w-full">
          Your Personal Travel Assistant - <span className="text-slate-500">Sterna</span>
        </h2>
        <h3 className="mt-2 text-3xl italic leading-10 text-zinc-500 w-[708px] max-md:max-w-full">
        At Sterna, our mission is to revolutionize and simplify the way you travel by leveraging the power of AI. Our platform acts as your personal travel assistant, seamlessly managing every aspect of your journey. From finding the best flights to automatically handling any issues that arise, Sterna ensures a smooth and worry-free travel experience. Let us take care of the details so you can focus on enjoying your trip.
        </h3>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d13bc602b101aefb9f3a21c1418af9c84046798dd4a348036fc6c93f026c0c5?apiKey=84610d0e1b774fbd87650936001dd652&"
          alt="Sterna Story Image"
          className="mt-6 max-w-full border-2 border-solid aspect-[33.33] border-slate-400 stroke-[2px] stroke-slate-400 w-[734px]"
        />
      </section>
      <h2 className="self-center mt-14 text-4xl font-semibold leading-10 text-center text-slate-500 max-md:mt-10">
        Why Choose <span className="text-slate-500">Sterna</span>
      </h2>
      <section className="self-center px-5 mt-12 w-full max-w-screen-xl max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <FeatureCard
            title="Comprehensive Search"
            description="Experience the future of flight booking with Sterna's innovative search capabilities. Our intelligent platform goes beyond basic price comparisons, diving deep into every factor that matters to you. From comfort and speed to safety and layovers, Sterna analyzes a multitude of variables to present you with the best options. Our intelligent system even predicts potential delays and weather disruptions, ensuring you’re always one step ahead. Say goodbye to endless scrolling and let Sterna find the best flight that ticks all your boxes."
          />
          <FeatureCard
            title="Real-Time Updates"
            description="With Sterna, you’re always in the know. From the moment you book until you reach your destination, our system continuously monitors your journey to keep you informed about gate changes, delays, cancellations, and more, so you never miss a beat. And if your plans gets disrupted  unexpectedly, no worries—with just one click, you can let Sterna take over the hassle of finding alternative flights or rescheduling your journey.  From unexpected layover extensions to last-minute rebookings, Sterna handles it all."
          />
          <FeatureCard
            title="Your Assistant"
            description="Imagine having a personal concierge who knows precisely what you need, when you need it. Sterna is tailored specifically for you, transforming your travel experience with actionable, real-time information. Need the quickest route to your connecting flight? Sterna can guide you step-by-step. Wondering about business facilities at the airport lounge? Ask Sterna, and get details on Wi-Fi, charging stations, and quiet zones. Need a quick way to get from the airport to your meeting? Sterna has you covered. With Sterna, you're never alone on your travels."
          />
        </div>
      </section>
      <footer className="flex justify-between items-center w-full mt-20 max-md:mt-10 max-md:flex-col max-md:gap-5">
        <section className="flex items-start max-md:flex-col max-md:items-center">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc6725362ccf368ec9b9fbe3065085cbb65ce3fa070dd9585866db0c405c80b8?apiKey=84610d0e1b774fbd87650936001dd652&"
            alt="Footer Logo"
            className="shrink-0 w-20 aspect-[1.04] max-md:mb-2"
          />
          <div className="flex flex-col px-5 mt-4 max-md:mt-0 max-md:items-center max-md:px-0">
            <h3 className="text-4xl text-black">Sterna</h3>
            <p className="mt-6 text-xl text-zinc-500">flysterna.com</p>
          </div>
        </section>
        <p className="text-xl text-zinc-500 max-md:text-center">Contact Us: <a href="mailto:contact@flysterna.com" className="text-red-500 hover:text-red-600">contact@flysterna.com</a></p>
        <div className="text-xl text-zinc-500 max-md:text-center">
          © {new Date().getFullYear()} Sterna. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default MyComponent;